<template>
  <div class="contentTitle">
    <h3>Dashboard</h3>
    <div class="btn-cluster">
      <a href="#" class="btn">Day</a><a href="#" class="btn selected">Week</a
      ><a href="#" class="btn">Month</a><a href="#" class="btn">Year</a>
    </div>
  </div>

  <div class="contentMenu">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="#">General</a>
      </li>
    </ul>
  </div>

  <div class="dashboard-wrapper">
    <div class="column1">
      <div class="dashboard-inner-block">
        <div class="ticket-left-top">
          <a href="" class="more">...</a>
          Issues
          <div class="ticket-left-date">
            February 8, 2021
            <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a
            ><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="graph">
          <img src="/assets/images/graph1.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="column2">
      <div class="dashboard-inner-block">
        <div class="ticket-left-top">
          <a href="" class="more">...</a>
          Tickets
          <div class="ticket-left-date">
            February 8, 2021
            <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a
            ><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="graph">
          <img src="/assets/images/graph2.jpg" alt="" />
        </div>
      </div>
      <div class="dashboard-inner-block">
        <div class="ticket-left-top">
          <a href="" class="more">...</a>
          Issues Progress
          <div class="ticket-left-date">
            February 8, 2021
            <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a
            ><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="graph">
          <img src="/assets/images/graph3.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="column1">
      <div class="dashboard-inner-block">
        <div class="ticket-left-top">
          <a href="" class="more">...</a>
          Inbox (123)
          <div class="ticket-left-date">
            February 8, 2021
            <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a
            ><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
          </div>
        </div>
        <ul class="ticket-listing">
          <li>
            <div class="left-area">
              Air compressor failure #1245
              <p>CC100013349039249DW<span>Client#1023</span></p>
              <p class="by">Andrew Horse <span>February 8, 2021</span></p>
              <p class="dot">...</p>
            </div>
            <div class="right-area">
              <br /><br /><br />
              <div class="more-pic">
                <img src="/assets/images/profile.png" class="pic" /><img
                  src="/assets/images/user-img.png"
                  class="pic"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="left-area">
              Air compressor failure #1245
              <p>CC100013349039249DW<span>Client#1023</span></p>
              <p class="by">Andrew Horse <span>February 8, 2021</span></p>
              <p class="dot">...</p>
            </div>
            <div class="right-area">
              <br /><br /><br />
              <div class="more-pic">
                <img src="/assets/images/profile.png" class="pic" /><img
                  src="/assets/images/user-img.png"
                  class="pic"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="left-area">
              Air compressor failure #1245
              <p>CC100013349039249DW<span>Client#1023</span></p>
              <p class="by">Andrew Horse <span>February 8, 2021</span></p>
              <p class="dot">...</p>
            </div>
            <div class="right-area">
              <br /><br /><br />
              <div class="more-pic">
                <img src="/assets/images/profile.png" class="pic" /><img
                  src="/assets/images/user-img.png"
                  class="pic"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="left-area">
              Air compressor failure #1245
              <p>CC100013349039249DW<span>Client#1023</span></p>
              <p class="by">Andrew Horse <span>February 8, 2021</span></p>
              <p class="dot">...</p>
            </div>
            <div class="right-area">
              <br /><br /><br />
              <div class="more-pic">
                <img src="/assets/images/profile.png" class="pic" /><img
                  src="/assets/images/user-img.png"
                  class="pic"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="left-area">
              Air compressor failure #1245
              <p>CC100013349039249DW<span>Client#1023</span></p>
              <p class="by">Andrew Horse <span>February 8, 2021</span></p>
              <p class="dot">...</p>
            </div>
            <div class="right-area">
              <br /><br /><br />
              <div class="more-pic">
                <img src="/assets/images/profile.png" class="pic" /><img
                  src="/assets/images/user-img.png"
                  class="pic"
                />
              </div>
            </div>
          </li>
          <li><a href="" class="btn">Go to Inbox</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Dashboard",
    };
  },
  mounted() {
    this.emitter.emit("activate-main-menu", "dashboard");
  },
};
</script>